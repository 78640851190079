import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import './index.css'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

import logo from '../../logo/logo.svg'

import LogRocket from 'logrocket'

const SignInPage = () => (
  <div>
    <SignInTwitter />
  </div>
)

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          LogRocket.identify(socialAuthUser.additionalUserInfo.username, {
            name: socialAuthUser.additionalUserInfo.username,
            email: socialAuthUser.user.email,
          })
          this.props.firebase.doAddUserToDatabase(
            socialAuthUser.additionalUserInfo.username,
            socialAuthUser.user.email,
            socialAuthUser.credential.accessToken,
            socialAuthUser.credential.secret,
          )
        } else {
          console.log(socialAuthUser)
          console.log(socialAuthUser.additionalUserInfo.profile.id.toString())
          this.props.firebase.doUpdateUserData(
            socialAuthUser.additionalUserInfo.username,
            socialAuthUser.credential.accessToken,
            socialAuthUser.credential.secret,
          )
        }
      })
      .then(() => {
        this.setState({ error: null })
        this.props.history.push(ROUTES.HOME)
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <div className="sign-in-form">
        <img alt="logo" className="sign-in-logo" src={logo} />
        <form onSubmit={this.onSubmit}>
          <button className="sign-in-button" type="submit">
            Sign In with Twitter
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    )
  }
}

const SignInTwitter = compose(withRouter, withFirebase)(SignInTwitterBase)

export default SignInPage

export { SignInTwitter }
