import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import Navigation from '../Navigation'
import SignInPage from '../SignIn'
import HomePage from '../Home'
import AccountPage from '../Account'

import * as ROUTES from '../../constants/routes'
import { withAuthentication, AuthUserContext } from '../Session'

import './index.css'

const App = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (authUser ? <AppAuth /> : <AppNonAuth />)}
    </AuthUserContext.Consumer>
  </div>
)

const AppAuth = () => (
  <Router>
    <div className="layout">
      <Navigation />
      <div className="content">
        <Route exact path={ROUTES.HOME}>
          <Redirect to={ROUTES.QUEUE} />
        </Route>
        <Route path={ROUTES.QUEUE} component={HomePage} />
        <Route path={ROUTES.SIGN_IN}>
          <Redirect to={ROUTES.QUEUE} />
        </Route>
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      </div>
    </div>
  </Router>
)

const AppNonAuth = () => (
  <Router>
    <Route exact path={ROUTES.HOME}>
      <Redirect to={ROUTES.SIGN_IN} />
    </Route>
    <Route path={ROUTES.ACCOUNT}>
      <Redirect to={ROUTES.SIGN_IN} />
    </Route>
    <Route path={ROUTES.QUEUE}>
      <Redirect to={ROUTES.SIGN_IN} />
    </Route>
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
  </Router>
)

export default withAuthentication(App)
