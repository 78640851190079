import React, { Component } from 'react'

import { withAuthorization } from '../Session'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose'
import Switch from 'react-switch'

import './index.css'

const INITIAL_STATE = {
  queue: ['loading tweets...'],
  deletedTweets: [],
  paused: true,
}

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.props.firebase.doGetUserData().then((response) => {
      this.setState({
        queue: response.data().queue,
        paused: response.data().paused,
        deletedTweets: response.data().deletedTweets,
      })
      console.log(this.state)
    })
  }

  onDelete = (event) => {
    // Index of tweet to delete
    let removeIndex = event.target.name

    let { queue, deletedTweets } = this.state

    let deletedTweet = queue[removeIndex]

    deletedTweets.push(deletedTweet)

    this.props.firebase
      .doUpdateDeletedTweets(deletedTweets)
      .then(console.log('Deleted Tweet'))
      .catch((error) => {
        console.log(error)
      })

    queue.splice(removeIndex, 1)

    this.setState({ queue: queue })

    this.props.firebase
      .doUpdateQueue(queue)
      .then(console.log('Successfully updated queue'))
      .catch((error) => {
        console.log(error)
      })

    event.preventDefault()
  }

  onToggle = (event) => {
    let paused = event
    this.setState({ paused: paused })
    this.props.firebase
      .doUpdatePaused(paused)
      .then(console.log('toggled pause tweets'))
      .catch((error) => {
        console.log(error)
      })
  }

  onShare = (event) => {
    // Index of tweet to share
    let tweetIndex = event.target.name

    // Share tweet

    // Remove tweet from queue
    const { queue } = this.state
    queue.splice(tweetIndex, 1)

    this.setState({ queue: queue })

    this.props.firebase
      .doUpdateQueue(queue)
      .then(console.log('Successfully updated queue'))
      .catch((error) => {
        console.log(error)
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onClick = (event) => {
    this.setState({ [event.target.name]: !this.state[event.target.name] })
  }

  render() {
    const { queue, paused } = this.state

    return (
      <div>
        <h1>Tweet Queue</h1>
        <div className="queue-page">
          <div className="queue">
            {paused ? <div className="paused-alert">Queue is currently paused</div> : null}
            {queue.map((tweet, i) => (
              <div key={i}>
                <div className="tweet">
                  <p className="tweet-text">{tweet}</p>
                </div>
                <div className="tweet-button-container">
                  <div className="tweet-buttons">
                    <button className="delete-button" name={i} onClick={this.onDelete}>
                      Delete
                    </button>
                    {/*<button className="share-button" name={i} onClick={this.onShare}>
                      Share Now
            </button>*/}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="schedule">
            <div className="schedule-card">
              <h2>Schedule</h2>
              <h3>Automatically tweets once per day at 10AM EST.</h3>
              {/*<h3>
                <a className="upgrade-link" href="https://www.birdybots.com/">
                  Upgrade
                </a>{' '}
                to tweet up to 10 times per day and schedule tweets at specific times.
              </h3>*/}
            </div>
            <div className="pause-tweets-container">
              <h3>Pause Tweets</h3>
              <Switch
                checked={paused}
                onChange={this.onToggle}
                onColor="#7560F5"
                offColor="#848493"
                onHandleColor="#1C1C2C"
                offHandleColor="#1C1C2C"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                height={25}
                width={48}
                className="pause-tweet-toggle"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const condition = (authUser) => !!authUser

const HomePage = compose(withFirebase, withAuthorization(condition))(Dashboard)

export default HomePage
