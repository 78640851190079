import React, { Component } from 'react'

import { withAuthorization } from '../Session'

import SignOutButton from '../SignOut'

import './index.css'

const INITIAL_STATE = {
  username: '',
  email: '',
}

class AccountPage extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.props.firebase.doGetUserData().then((response) => {
      this.setState({ username: response.data().username, email: response.data().email })
      console.log(this.state)
    })
  }

  render() {
    const { username, email } = this.state

    return (
      <div className="account-page">
        <h1>Account</h1>
        <div className="card">
          <h2>Billing: Free Plan</h2>
          <h3>Allows 1 tweet per day.</h3>
          {/*<button className="upgrade-button">Upgrade</button>*/}
        </div>
        <div className="card">
          <h2>Account Details</h2>
          <h3>Username: {username}</h3>
          <h3>Email: {email}</h3>
          <SignOutButton />
        </div>
      </div>
    )
  }
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(AccountPage)
