import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyCO9M49Ref_k2ZfDos6BnKbEXwSC4oVfm8',
  authDomain: 'birdybots-4bcf9.firebaseapp.com',
  databaseURL: 'https://birdybots-4bcf9.firebaseio.com',
  projectId: 'birdybots-4bcf9',
  storageBucket: 'birdybots-4bcf9.appspot.com',
  messagingSenderId: '343779275647',
  appId: '1:343779275647:web:8ab50da25250115ed8604a',
  measurementId: 'G-5R1H6NESVN',
}

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.auth = app.auth()
    this.firestore = app.firestore()
    this.twitterProvider = new app.auth.TwitterAuthProvider()
  }

  // *** Auth API ***

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignOut = () => this.auth.signOut()

  // *** User API **

  doAddUserToDatabase = (username, email, accessToken, secret) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).set({
      username: username,
      email: email,
      accessToken: accessToken,
      secret: secret,
      deletedTweets: [],
      alreadyTweeted: [],
      queue: [],
      paused: true,
    })

  doUpdateQueue = (queue) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      queue: queue,
    })

  doUpdateDeletedTweets = (tweets) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      deletedTweets: tweets,
    })

  doUpdateUserData = (username, accessToken, secret) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      username: username,
      accessToken: accessToken,
      secret: secret,
    })

  doUpdatePaused = (paused) =>
    this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
      paused: paused,
    })

  doGetUserData = () => this.firestore.collection('users').doc(this.auth.currentUser.uid).get()
}

export default Firebase
