import React from 'react'
import { NavLink } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'

import { AuthUserContext } from '../Session'

import './index.css'

import logo from '../../logo/logo.svg'

import { FaStream, FaUser } from 'react-icons/fa'

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (authUser ? <NavigationAuth /> : <NavigationNonAuth />)}
    </AuthUserContext.Consumer>
  </div>
)

const NavigationAuth = () => (
  <div className="nav">
    <img alt="logo" src={logo} />
    <NavLink activeClassName="nav-item--active" className="nav-item" to={ROUTES.QUEUE}>
      <div className="nav-item-container">
        <FaStream size={25} />
        <div className="nav-item-text">Queue</div>
      </div>
    </NavLink>
    <NavLink activeClassName="nav-item--active" className="nav-item" to={ROUTES.ACCOUNT}>
      <div className="nav-item-container">
        <FaUser size={25} />
        <div className="nav-item-text">Account</div>
      </div>
    </NavLink>
  </div>
)

const NavigationNonAuth = () => <div></div>

export default Navigation
