import React from 'react'

import { withFirebase } from '../Firebase'

import './index.css'

const SignOutButton = ({ firebase }) => (
  <button className="sign-out-button" type="button" onClick={firebase.doSignOut}>
    Sign Out
  </button>
)

export default withFirebase(SignOutButton)
